import { APIFilter } from '@/utils/api'

export default {
  async selectFormData (Vue, id) {
    const apiFilter = new APIFilter()
    apiFilter
      .addExact('idpresupuestocli', id)
    const resp = await Vue.$api.call('presupuestocli.select', { filter: apiFilter })
    return resp.data.result.dataset[0]
  },
  async selectLookups (Vue) {
    let apiCalls = []
    const apiFilterEstadoActivo = new APIFilter()
      .addGT('estado', 0)
    apiCalls.push({ name: 'selectIdiomas', method: 'idioma.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectAgenteComercial', method: 'agenteComercial.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectCanalComunicacion', method: 'canalComunicacion.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectCanalConocimiento', method: 'canalConocimiento.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectTipoRiesgo', method: 'tipoRiesgo.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectGradoSeguridad', method: 'gradoSeguridad.select', params: { filter: apiFilterEstadoActivo } })
    const apiFilterPago = new APIFilter()
      .addGT('estado', 0)
      .addExact('cliente', 1)
    apiCalls.push({ name: 'selectFormaPago', method: 'formaPago.select', params: { filter: apiFilterPago } })
    apiCalls.push({ name: 'selectCondicionPago', method: 'condicionPago.select', params: { filter: apiFilterPago } })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectLookupsFormData (Vue, idcliente, idclientePotencial, idtipoRiesgo) {
    let apiCalls = []
    const apiFilterDirecciones = new APIFilter()
    apiFilterDirecciones
      .addGT('estado', 0)
    if (idclientePotencial) {
      apiFilterDirecciones.addExact('idcliente_potencial', idclientePotencial)
    } else {
      apiFilterDirecciones.addExact('idcliente', idcliente)
    }
    apiCalls.push({ name: 'selectClienteDireccion', method: 'clienteDireccion.select', params: { filter: apiFilterDirecciones } })
    const apiFilterTipoRiesgo = new APIFilter()
    apiFilterTipoRiesgo
      .addExact('idtipo_riesgo', idtipoRiesgo)
    apiCalls.push({ name: 'selectTipoRiesgo', method: 'tipoRiesgo.select', params: { filter: apiFilterTipoRiesgo } })
    return await Vue.$api.batchCall(apiCalls)
  }
}
