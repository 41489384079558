import { API_RESULT_DATATYPES } from '@/utils/api'

export default {
  props: {
    id: {
      type: [Number, String],
      required: false
    },
  },
  computed: {
    isInsert () {
      if (this.id) {
        return false
      } else {
        return true
      }
    },
    isEdit () {
      if (this.id) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    async id (newValue) {
      // loadFormData del form donde se aplica el mixin
      // cambia el ID del objeto que se esta editando
      await this.loadFormData(newValue)
    }
  },
  methods: {
    emitChangeValues () {
      this.$emit('change-values', this.form)
    },
    async loadForm () {
      if (!this.isInsert) {
        // loadFormData del form donde se aplica el mixin
        await this.loadFormData(this.id)
      }
      this.emitChangeValues()
      this.$watch('form', this.emitChangeValues, { deep: true })
    },
    addFormRulesAPI (columns) {
      for (const column of columns) {
        // controlar longitud máxima para campos string
        if (column.type === API_RESULT_DATATYPES.string && column.name in this.form) {
          if (!(column.name in this.formRules)) {
            this.$set(this.formRules, column.name, [])
          }
          this.formRules[column.name].push(
            v => (v || '').length <= column.size || `La longitud máxima es de ${column.size} caracteres`,
          )
        }
      }
    }
  },
}
